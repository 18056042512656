import React, { useEffect } from "react";
import beitebil from "./car.png";
import TheMan from "./TheMan";

const Bilen = () => {
  const [x, setX] = React.useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setX((x) => (x + 1) % 300);
    }, 25);

    return () => clearInterval(interval);
  }, []);

  return (
    <div
      style={{
        position: "relative",
        bottom: "0%",
        right: `${x - 150}%`,
        height: "100%",
        width: "100%",
      }}
    >
      <img
        style={{
          position: "absolute",
          height: "20%",
          bottom: "0%",
          transform: "translate(-50%, -50%)",
        }}
        src={beitebil}
        alt="Bilen"
      />
      <TheMan />
    </div>
  );
};

export default Bilen;
